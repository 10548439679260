<template>
  <div>
    <b-card-header class="d-block">
      <b-row class="d-flex align-items-center">
        <b-col
          cols="12"
          md="6"
        >
          <b-card-title>{{ dataItem.title }}</b-card-title>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="text-right text-primary font-weight-bolder font-medium-3"
        >
          #{{ dataItem.onumber }}
        </b-col>

        <b-col
          cols="12"
          md="2"
          class="text-right"
        >
          <b-button
            :href="downloadUrl"
            variant="danger"
            target="_blank"
          >
            <feather-icon icon="PrinterIcon" />
            Yazdır
          </b-button>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <div class="text-primary font-weight-bold">
            Teklif Tarihi
          </div>
          <div>
            {{ moment(dataItem.odate).format('LL') }}
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <div class="text-primary font-weight-bold">
            Geçerlilik Tarihi
          </div>
          <div>
            {{ dataItem.vdate? moment(dataItem.vdate).format('LL') : '-' }}
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <div class="text-primary font-weight-bold">
            Araç Plakası
          </div>
          <div>
            {{ dataItem.license_plate }}
          </div>
        </b-col>
        <b-col
          v-if="dataItem.id_com_service_tracing"
          cols="12"
          md="3"
        >
          <div class="text-primary font-weight-bold">
            Servis Randevu Kaydı
          </div>
          <div>
            <b-link
              :to="'/service/view/'+dataItem.id_com_service_tracing"
              target="_blank"
              class="text-warning"
            >
              Kayda gitmek için tıklayın.
            </b-link>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="font-weight-bold font-medium-3 text-primary mt-2">
            Metin
          </div>
          <div v-html="dataItem.content" />
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BRow, BCol, BCardHeader, BCardTitle, BCardBody, BButton, BLink,
} from 'bootstrap-vue'

export default {
  name: 'OfferSummary',
  components: {
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BLink,
  },
  computed: {
    dataItem() {
      return this.$store.getters['batteryOffers/getOffer']
    },
    downloadUrl() {
      return `${this.$store.state.app.baseURL}/exports/data/BatteryOffers/pdf?token=${localStorage.getItem('downloadToken')}&id=${this.$route.params.id}`
    },
  },
}
</script>
