<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>Teklif</span>
        </template>
        <b-card no-body>
          <offer-summary />
        </b-card>
        <div
          v-for="(line,key) in lineData"
          :key="key"
        >
          <offer-view-line :data-key="key" />
        </div>
        <b-card title="Şartlar">
          <div v-html="dataItem.terms" />
        </b-card>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Kartı</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>

  </div>
</template>
<script>

import {
  BTab, BTabs, BCard,
} from 'bootstrap-vue'
import { localize } from 'vee-validate'
import Customer from '@/views/Crm/View/Customer.vue'
import OfferSummary from '@/views/BatteryOffers/Components/View/Summary.vue'
import OfferViewLine from '@/views/BatteryOffers/Components/View/lineCard.vue'

export default {
  name: 'OfferAdd',
  components: {
    OfferViewLine,
    OfferSummary,
    BTabs,
    Customer,
    BTab,
    BCard,
  },
  computed: {
    dataItem() {
      return this.$store.getters['batteryOffers/getOffer']
    },
    lineData() {
      const lineData = this.$store.getters['batteryOffers/getOffer']
      return lineData.lines
    },
    offerLinePriceItems() {
      return this.$store.getters['offerLinePriceItems/getOfferLinePriceItems']
    },
    offerTerm() {
      return this.$store.getters['batteryOffers/getTermsContent']
    },
    saveData() {
      return this.$store.getters['batteryOffers/getOfferSaveStatus']
    },

  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.dataItem.submitStatus = false
    },
  },
  created() {
    localize('tr')
    this.$store.commit('batteryOffers/OFFER_RESET')
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('batteryOffers/offerView', this.$route.params.id)
        .then(response => {
          if (response) {
            this.getCustomer(response.id_com_customer)
          }
        })
    },
    getCustomer(customer) {
      this.$store.dispatch('customers/customerView', customer)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
