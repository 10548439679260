<template>
  <div>
    <b-card title="Ürün Bilgisi">
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
          <b-row>
            <b-col>
              <b-list-group>
                <b-list-group-item class="border-0">
                  <div class="font-weight-bold text-primary border-0">
                    Teklif Edilen Ürün
                  </div>
                  {{ lineData[dataKey].title }}
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col>
              <b-list-group>
                <b-list-group-item class="border-0">
                  <div class="font-weight-bold text-primary border-0">
                    Teklif Fiyatı
                  </div>
                  {{ lineData[dataKey].offer_price }} TL
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  name: 'OfferViewLine',
  components: {
    BListGroupItem,
    BListGroup,

    BCard,
    BRow,
    BCol,
  },
  props: {
    dataKey: {
      type: Number,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['batteryOffers/getOffer']
    },
    lineData() {
      const LineData = this.$store.getters['batteryOffers/getOffer']
      return LineData.lines
    },
  },
  methods: {
    getData() {
      this.$store.dispatch('batteryOffers/offerView', this.$route.params.id)
    },
  },
}
</script>
